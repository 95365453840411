import React from "react"
import "react-multi-carousel/lib/styles.css"
import vikrant from "../images/newDesign/carousalImages/VikrantAbrol.jpeg"
import Aayushi from "../images/newDesign/carousalImages/Aayushi.jpg"
import Anupama from "../images/newDesign/carousalImages/Anupama.jpg"
import Hirak from "../images/newDesign/carousalImages/Hirak.jpg"
import sharukh from "../images/newDesign/carousalImages/sharukh.jpg"
import abhi from "../images/newDesign/carousalImages/abhi.jpeg"
import nancy from "../images/newDesign/carousalImages/nancy.jpeg"
import puneeta from "../images/newDesign/carousalImages/puneeta.jpg"
import { Card } from "react-bootstrap"
import Carousel from "react-multi-carousel"
export default function team() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const executionTeam = [
    {
      url: vikrant,
      name: "Mr. Vikrant Abrol",
      designation: "Founder - Indian ESG Network",
      linkedin: "https://www.linkedin.com/in/vikrant-abrol-066946146/",
    },
    {
      url: puneeta,
      name: "CA. Puneeta Puri",
      designation: "",
      linkedin: "https://www.linkedin.com/in/ca-puneeta-puri-35208b9/",
    },
    {
      url: Aayushi,
      name: "Ms. Aayushi Jauhari",
      designation: "",
      linkedin: "https://www.linkedin.com/in/aayushijauhari/",
    },
    {
      url: Anupama,
      name: "Ms. Anupama Jain",
      designation: "",
      linkedin: "https://www.linkedin.com/in/anupama-j-08b255272/",
    },
    {
      url: sharukh,
      name: "Mr. Shahrukh Khan",
      designation: "",
      linkedin: "https://www.linkedin.com/in/shahrukh-khan-08787b8b/",
    },
    {
      url: abhi,
      name: "Mr. Abhishek Challa ",
      designation: "",
      linkedin: "https://www.linkedin.com/in/abhishek-challa-434a14190/",
    },
    {
      url: nancy,
      name: "Ms. Nancy Benjwal",
      designation: "",
      linkedin: "https://www.linkedin.com/in/nancy-benjwal-235678150/",
    },
    {
      url: Hirak,
      name: "Mr. Hirak Jyoti Gogoi",
      designation: "",
      linkedin: "https://www.linkedin.com/in/hirakjyoti-gogoi-214233218/",
    },
  ]
  return (
    <>
      <div className="mt-5 pt-5 mb-5 px-5">
        <h1 className="heading mb-5"> INDIAN ESG NETWORK TEAM</h1>
        <Carousel
          responsive={responsive}
          // arrows={true}
          infinite={true}
          autoPlay={true}
        >
          {executionTeam.map(data => {
            return (
              <div className="m-3">
                <Card className="custom-card" style={{ height: "28rem" }}>
                  <Card.Img
                    variant="top"
                    // style={{ height: "65%", width: "100%" }}
                    src={data.url}
                  />
                  {/* <Card.Body>
                    <div className="card-heading fw-bold">{data.name}</div>
                    <p>{data.designation}</p>
                    {data.linkedin && (
                      <a target="_blank" href={data.linkedin}>
                        Linkedin Profile
                      </a>
                    )}
                  </Card.Body> */}
                  <Card.Body>
                    <div className="row">
                      <div className="col-9">
                        <div className="card-heading fw-bold">{data.name}</div>
                      </div>
                      {data.linkedin && (
                        <div className="col-3 text-end">
                          <a target="_blank" href={data.linkedin}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              class="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </div>
                      )}
                    </div>
                    <p className="designation-text pt-2">{data.designation}</p>
                  </Card.Body>
                </Card>
              </div>
            )
          })}
        </Carousel>
      </div>
    </>
  )
}
